import React     from 'react';
import PropTypes from 'prop-types';

import 'react-accessible-accordion/dist/fancy-example.css';

import {
  Accordion as AAccordion,
  AccordionItem, AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const Accordion = ({ data, titleKey, contentKey }) => {
  return (
    <AAccordion allowZeroExpanded>
      {data.map(acc => (
        <AccordionItem key={acc[titleKey]}>
          <AccordionItemHeading>
            <AccordionItemButton>
              <strong>{acc[titleKey]}</strong>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <h3>{acc[titleKey]}</h3>
            <div dangerouslySetInnerHTML={{ __html: acc[contentKey] }}/>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </AAccordion>
  )
};

Accordion.propTypes = {
  data: PropTypes.array.isRequired,
  titleKey: PropTypes.string,
  contentKey: PropTypes.string,
};

Accordion.defaultProps = {
  titleKey: 'title',
  contentKey: 'text',
};

export default Accordion;
